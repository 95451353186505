import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import flowerIcon from '../img/flowers.svg'; // Importer l'icône flower.svg

const ArtworkSlider = () => {
  const [artworks, setArtworks] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false); // Suivre l'état de la première image

  // Charger les œuvres à partir du fichier JSON
  useEffect(() => {
    const fetchArtworks = async () => {
      try {
        const response = await fetch('/data/flowers.json'); // Charger le fichier flowers.json
        const data = await response.json();
        setArtworks(data);
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors du chargement des œuvres :", error);
        setLoading(false);
      }
    };

    fetchArtworks();
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? artworks.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === artworks.length - 1 ? 0 : prevIndex + 1));
  };

  if (loading) {
    // Utiliser flower.svg en tant que spinner
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Box
          component="img"
          src={flowerIcon}
          alt="Loading"
          sx={{
            width: '45px',
            height: '45px',
            animation: 'spin 6s linear infinite',
          }}
        />
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </Box>
    );
  }

  if (artworks.length === 0) {
    return <div>No artworks available</div>;
  }

  return (
    <Box 
      sx={{ 
        position: 'relative', 
        width: '100%', 
        height: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        overflow: 'hidden'
      }}
    >
      <IconButton
        onClick={handlePrevClick}
        sx={{ 
          position: 'absolute', 
          left: 15, 
          zIndex: 1, 
          color: 'black', 
          padding: '5px',
          backgroundColor: 'transparent',
          '&:hover': { backgroundColor: 'transparent' },
          '&:active': { backgroundColor: 'transparent' },
          '&:focus': { backgroundColor: 'transparent' },
          outline: 'none',
        }}
      >
        <ArrowBackIosIcon sx={{ fontSize: '20px' }} />
      </IconButton>

      <Box
        sx={{
          width: {
            xs: '70vw', // 60% width on mobile
            md: 'auto', // auto width on desktop
          },
          height: {
            xs: 'auto', // auto height on mobile
            md: '80vh', // 80% height on desktop
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <motion.img
          key={artworks[currentIndex].id}
          src={`/assets/images/${artworks[currentIndex].mainImage}`}
          alt={artworks[currentIndex].title}
          initial={{ opacity: 0 }} // Commencer avec une opacité à 0
          animate={{ opacity: isFirstImageLoaded ? 1 : 0 }} // Animation fade-in pour la première image
          transition={{ duration: 1.4 }} // Durée de l'animation
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            cursor: 'pointer',
          }}
          onLoad={() => setIsFirstImageLoaded(true)} // Déclencher l'animation après le chargement de la première image
          onClick={() => window.location.href = `/artwork/${artworks[currentIndex].id}`} // Redirection mise à jour
        />
      </Box>

      <IconButton
        onClick={handleNextClick}
        sx={{ 
          position: 'absolute', 
          right: 15, 
          zIndex: 1, 
          color: 'black', 
          padding: '5px',
          backgroundColor: 'transparent',
          '&:hover': { backgroundColor: 'transparent' },
          '&:active': { backgroundColor: 'transparent' },
          '&:focus': { backgroundColor: 'transparent' },
          outline: 'none',
        }}
      >
        <ArrowForwardIosIcon sx={{ fontSize: '20px' }} />
      </IconButton>
    </Box>
  );
};

export default ArtworkSlider;
