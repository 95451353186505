import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Header from '../components/Header';
import ArtworkSlider from '../components/ArtworkSlider';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';

const Index = () => {
  const [showWelcome, setShowWelcome] = useState(true);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');

    if (!hasVisited) {
      // Afficher l'image pendant 3 secondes
      setTimeout(() => {
        setShowWelcome(false);
        localStorage.setItem('hasVisited', 'true');
      }, 6000);
    } else {
      setShowWelcome(false);
    }
  }, []);

  return (
    <Box>
      <Helmet>
        <title>KAJIKA AKI</title>
      </Helmet>
      <AnimatePresence>
        {showWelcome && (
          // Écran de bienvenue avec effet de fade-in et fade-out
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              width: '100vw',
              backgroundColor: 'white',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 9999,
            }}
          >
            <motion.img
              src="/assets/images/home.svg"
              alt="Welcome"
              style={{ maxHeight: '35%', maxWidth: '80%'  }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 3 }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {!showWelcome && (
        // Contenu principal après l'écran de bienvenue
        <Box>
          <Header />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <ArtworkSlider />
          </motion.div>
        </Box>
      )}
    </Box>
  );
};

export default Index;
