import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import Lenis from '@studio-freight/lenis';

export default function ZoomArtwork({ imageSrc }) {
  const [scale, setScale] = useState(1); // Commencer à l'échelle normale
  const animationFrame = useRef(null);
  const scrollPosition = useRef(0); // Utilisation d'un useRef pour éviter des rerenders inutiles

  useEffect(() => {
    // Initialiser Lenis pour un défilement fluide
    const lenis = new Lenis({
      duration: 1.2, // Durée de lissage
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // Courbe de lissage
    });

    function raf(time) {
      lenis.raf(time); // Synchroniser Lenis avec le rafraîchissement d'animation
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    const handleScroll = () => {
      if (animationFrame.current) return;

      animationFrame.current = requestAnimationFrame(() => {
        scrollPosition.current = window.scrollY;
        const newScale = Math.min(5, 1 + scrollPosition.current / 1500); // Zoomer progressivement jusqu'à une échelle de 6
        setScale(newScale);
        animationFrame.current = null;
      });
    };

    // Ajouter un léger délai pour éviter un calcul trop rapide au premier scroll
    const timeout = setTimeout(() => {
      window.addEventListener('scroll', handleScroll);
    }, 100); // Attendre 100ms après le chargement

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (animationFrame.current) cancelAnimationFrame(animationFrame.current);
      lenis.destroy(); // Nettoyer Lenis à la fin
      clearTimeout(timeout); // Nettoyer le timeout
    };
  }, []);

  return (
    <Box
      sx={{
        height: '500vh', // Grande hauteur pour permettre le scroll
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed', // Pour rester centré
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          pt: 0,
        }}
      >
        <Box
          component={motion.img}
          src={imageSrc} // Image passée en prop
          alt="Artwork"
          initial={{ opacity: 0 }} // Initialement invisible
          animate={{ opacity: 1 }} // Transition vers une opacité de 1 (visible)
          transition={{ duration: 1.5 }} // Durée de l'animation
          sx={{
            width: {
              xs: '80vw', // Largeur de 60% sur mobile
              md: 'auto', // Largeur automatique sur desktop
            },
            height: {
              xs: 'auto', // Hauteur automatique sur mobile
              md: '80vh', // Hauteur de 80% sur desktop
            },
            maxHeight: '250vh',
            maxWidth: '250vw',
            transform: `scale(${scale})`, // Zoom basé sur la position de scroll
            transition: 'transform 0.1s ease-out', // Transition plus rapide pour réduire les lags
          }}
        />
      </Box>
    </Box>
  );
}
