import React, { useEffect, useRef } from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import Header from '../components/Header';
import { motion } from 'framer-motion';
import Lenis from '@studio-freight/lenis';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const About = () => {
  const lenisRef = useRef(null);
  const { t } = useTranslation();

  // Gestion du scrolling smooth avec Lenis
  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    });
    lenisRef.current = lenis;

    function raf(time) {
      if (lenis) {
        lenis.raf(time);
        requestAnimationFrame(raf);
      }
    }
    requestAnimationFrame(raf);

    return () => {
      if (lenisRef.current) {
        lenisRef.current.destroy();
      }
    };
  }, []);

  // Données statiques extraites de about.json maintenant intégrées avec i18n
  const data = {
    biography: t('about_page.biography'),
    exhibitions: [
      {
        year: "2024",
        title: t('about_page.exhibitions.0.title'),
        organized_by: t('about_page.exhibitions.0.organized_by'),
        city: t('about_page.exhibitions.0.city'),
        venue: t('about_page.exhibitions.0.venue'),
        dates: "dec 2024",
        type: "SOLO"
      },
      {
        year: "2022",
        title: t('about_page.exhibitions.1.title'),
        organized_by: t('about_page.exhibitions.1.organized_by'),
        city: t('about_page.exhibitions.1.city'),
        venue: t('about_page.exhibitions.1.venue'),
        dates: "avril 2022",
        type: "SOLO"
      }
    ],
    awards: [
      {
        title: t('about_page.awards.0.title'),
        event: t('about_page.awards.0.event'),
        year: 2021
      },
      {
        title: t('about_page.awards.1.title'),
        event: t('about_page.awards.1.event'),
        year: 2021
      },
      {
        title: t('about_page.awards.2.title'),
        event: t('about_page.awards.2.event'),
        year: 2022
      }
    ]
  };

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const blockVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      <Helmet>
        <title>KAJIKA AKI - {t('about')}</title>
      </Helmet>
      <Header />
      <Container sx={{ pt: { xs: 7, md: 10 }, pb: 10, px: { xs: 5, md: 15 } }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              variants={fadeInVariants}
            >
              <Box>
                <img
                  src="/assets/images/portrait.jpg"
                  alt={t('about_page.biography')}
                  style={{ width: '100%', borderRadius: '4px' }}
                />
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={8}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              variants={fadeInVariants}
            >
              <Box sx={{ lineHeight: '1.8' }}>
                {data.biography.split('\n\n').map((paragraph, index) => (
                  <Typography key={index} variant="body1" paragraph>
                    {paragraph}
                  </Typography>
                ))}
              </Box>
            </motion.div>
          </Grid>
        </Grid>

        <Box mt={4}>
        <Typography variant="h6" component="h2" gutterBottom>
  {t('about_page.extitle')}
</Typography>
          {data.exhibitions.map((exhibition, index) => (
            <motion.div
              key={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              variants={blockVariants}
            >
              <Grid
                container
                spacing={0}
                alignItems="center"
                sx={{
                  borderTop: '1px solid #ccc',
                  borderBottom: index === data.exhibitions.length - 1 ? '1px solid #ccc' : 'none',
                  paddingY: 2,
                }}
              >
                <Grid item xs={2}>
                  <Typography textTransform={'uppercase'} variant="body2">
                    {exhibition.dates}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {exhibition.title}
                  </Typography>
                  <Typography variant="body2">
                    {exhibition.organized_by} {exhibition.city} {exhibition.venue}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" align="right">
                    {exhibition.type}
                  </Typography>
                </Grid>
              </Grid>
            </motion.div>
          ))}
        </Box>

        <Box sx={{ mt: 6 }}>
        <Typography variant="h6" component="h2" gutterBottom>
  {t('about_page.awtitle')}
</Typography>
          {data.awards.map((award, index) => (
            <motion.div
              key={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              variants={blockVariants}
            >
              <Grid
                container
                spacing={0}
                alignItems="center"
                sx={{
                  borderTop: '1px solid #ccc',
                  borderBottom: index === data.awards.length - 1 ? '1px solid #ccc' : 'none',
                  paddingY: 2,
                }}
              >
                <Grid item xs={2}>
                  <Typography textTransform={'uppercase'} variant="body2">
                    {award.year}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>{award.event}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography textTransform={'uppercase'} variant="body2">
                    {award.title}
                  </Typography>
                </Grid>
              </Grid>
            </motion.div>
          ))}
        </Box>
      </Container>
    </>
  );
};

export default About;
