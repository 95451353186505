import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    resources: {
      fr: {
        translation: {
          fresques: "Fresques",
          about: "À propos",
          contact: "Contact",
          artwork_titles: {
            "1": "Janvier 2023",
            "2": "Février 2024",
            "3": "Décembre 2023",
            "4": "Janvier 2024",
            "5": "Décembre 2023",
            "6": "Mars 2024",
            "7": "Novembre 2023",
          },
          artwork_descriptions: {
            "1": "La Fleur Bleue de Joie peinte en outremer doux et cyan tyran dépeint l’Amour inconditionnel.",
            "2": "", 
            "3": "Portrait de quelqu'un que j'aime.", 
            "4": "Discussion avec quelqu'un que j'aime.", 
            "5": "Autoportrait.", 
            "6": "",
            "7": "",  
          },
          artwork_sizes: {
            "1": "Encre de chine et aquarelle sur papier - 150x150cm",
            "2": "Aquarelle sur papier - 100x100cm",
            "3": "Aquarelle sur papier - 65x65cm",
            "4": "Aquarelle sur papier - 100x100cm",
            "5": "Aquarelle sur papier - 65x65cm",
            "6": "Aquarelle sur papier - 100x100cm",
            "7": "Aquarelle sur papier - 65x65cm",
          },
          about_page: {
            extitle : 'Expositions',
            awtitle:'Prix',
            biography: `Kajika Aki Ferrazzini est née en 1993 à Paris. Après avoir obtenu un diplôme des Métiers d'Arts et du Design à l’école Estienne en 2016, elle commence un Master CRFA (Concepteur et Réalisateur de films d’animation) à l’école des Gobelins qu’elle arrête en 2017 pour se consacrer à son premier court-métrage MOM. Produit en solo dans une période d’un an, MOM a gagné le prix du Meilleur film au Festival International d’Animation de Tbilisi (Georgie, 2021), a obtenu une Mention spéciale au Future Film Festival (Italie, 2021), au Anima - Best of shorts 2022 (Belgique), et a fait partie de la sélection officielle du Festival du film International d’Annecy en 2021.

En 2019 et 2020, elle réalise The life and the death of the little mermaid et IMIEAMORI, deux oeuvres picturales de 1m50 sur 10m de long, réalisées entièrement sur papier, sans esquisse préalable, à l’encre de chine et aquarelle.

Ses oeuvres sont exposées du 16 mars 2022 au 16 mai 2022 à la Folie Barbizon, sous l’œil bienveillant de Lionel Bensemoun : « Kajika embrasse la nature humaine en dévoilant sa propre intimité, ses personnages oscillent entre les émotions violentes et douces. Ils pleurent, vomissent, tuent ; ils rient, embrassent, aiment, et fleurissent aussi, dans une constante recherche d'harmonie au sein du chaos, car pour elle c’est au sein des plus grands drames que l'être humain peut trouver l'opportunité de laisser s'épanouir en lui les plus douces fleurs d'éternité. »`,
            exhibitions: [
              {
                year: "2024",
                title: "Claim for Peace",
                organized_by: "Tresor Foundation",
                city: "Berlin",
                venue: "",
                dates: "déc 2024",
                type: "SOLO",
              },
              {
                year: "2022",
                title: "Atomic Bombs",
                organized_by: "",
                city: "",
                venue: "La Folie Barbizon",
                dates: "avril 2022",
                type: "SOLO",
              },
            ],
            awards: [
              {
                title: "Prix du Meilleur film",
                event: "Festival International d’Animation de Tbilisi, Géorgie",
                year: 2021,
              },
              {
                title: "Mention spéciale",
                event: "Future Film Festival, Italie",
                year: 2021,
              },
              {
                title: "Mention spéciale",
                event: "Anima - Best of shorts, Belgique",
                year: 2022,
              },
            ],
          },
          frescoes_page: {
            title: "KAJIKA - Fresques",
            left_image_alt: "The life and death of the little mermaide",
            right_image_alt: "Imieiamori",
            left_title: "The life and death of the little mermaid",
            right_title: "Imieiamori",
            left_subtitle: "1,5x10m, encre sur papier",
            right_subtitle: "1,5x10m, encre et aquarelle sur papier"
          },
          flowers_page: {
            title: "Flowers of Joy",
            description_1: "Les Fleurs de Joie sont nées de l’Amour que j’ai eu pour quelqu’un. Avec cette personne, je me sentais heureuse et le temps passait différemment. Cette réalité ne m’a jamais lâchée, comme si elle était partie intégrante de quelque chose de plus grand et que le reste était devenu dérisoire.",
            description_2: "Je me suis donc mise à la recherche de la ligne parfaite, celle qui sans aucune hésitation s’applique; comme le revers d’un tennisman surentraîné ou les pas d’une ballerine. Une forme sans fond. Quelque chose qui se tient seul, de façon évidente. Après avoir mené un travail intense sur la forme et la ligne, les Fleurs Bleues sont apparues.",
            description_3: "Les Fleurs Bleues sont formées de 5 branches et 5 pétales, elles ont en leur centre un cœur en étoile et sont serties de 5 petits points de joie.",
            description_4: "La Fleur de Joie est une forme où se place la couleur. Mes peintures de Fleurs Bleues sont peintes sur du papier découpé à la main, avec de l’aquarelle faite maison. La peinture aquarelle est fabriquée dans mon atelier, avec les pigments et les liants trouvés lors de mes voyages.",
            description_5: "Les couleurs s’appliquent en une forme : le quadrillage des Fleurs de Joie. Cette forme est le résultat de milliers d’heures de travail et de recherches. La peinture y est appliquée sans réflexion, ni hésitation : les fleurs existent déjà dès lors où le papier est découpé.",
            description_6: "Il n’y a ni idées, ni symbolisme derrière l’objet de la Fleur Bleue, c’est un acte de pur causalité/conséquences, une avalanche dont les premières pierres étaient vêtues d’un amour absolu et inconditionnel pour l’ensemble et le tout, de joie et d’amour du vivant. Elles sont en toute simplicité, maintenant, de la couleur appliquée sur du blanc."
          },
          meta: {
            description: "Artiste, peintre, illustratrice, auteure et réalisatrice de film d’animation, Kajika Aki dépeint sa réalité au travers de son oeuvre artistique. Son travail navigue entre violence et douceur, réalisme et abstraction; son oeuvre est un cadre où la peine humaine et les plus grandes épreuves peuvent donner naissance à la justesse de la ligne et à la précision."
          }
        }
      },
      en: {
        translation: {
          fresques: "Frescoes",
          about: "About",
          contact: "Contact",
          artwork_titles: {
            "1": "January 2023",
            "2": "February 2024",
            "3": "December 2023",
            "4": "January 2024",
            "5": "December 2023",
            "6": "March 2024",
            "7": "November 2023",
          },
          artwork_descriptions: {
            "1": "The Blue Flower of Joy painted in soft ultramarine and tyrant cyan depicts unconditional Love.",
            "2": "", 
            "3": "Portrait of someone I love.", 
            "4": "Discussion with someone I love.", 
            "5": "Self-portrait.", 
            "6": "",
            "7": "",  
          },
          artwork_sizes: {
            "1": "Indian ink and watercolor on paper - 150x150cm",
            "2": "Watercolor on paper - 100x100cm",
            "3": "Watercolor on paper - 65x65cm",
            "4": "Watercolor on paper - 100x100cm",
            "5": "Watercolor on paper - 65x65cm",
            "6": "Watercolor on paper - 100x100cm",
            "7": "Watercolor on paper - 65x65cm",
          },
          about_page: {
            extitle : 'Exhibitions',
            awtitle:'Awards',
            biography: `Kajika Aki Ferrazzini was born in 1993 in Paris. After obtaining a degree in Arts and Design at the Estienne school in 2016, she began a Master's degree in CRFA (Animation Film Designer and Director) at Gobelins, which she left in 2017 to focus on her first short film MOM. Produced solo over a period of one year, MOM won the Best Film award at the Tbilisi International Animation Festival (Georgia, 2021), received a Special Mention at the Future Film Festival (Italy, 2021) and Anima - Best of shorts 2022 (Belgium), and was part of the official selection of the Annecy International Film Festival in 2021.

In 2019 and 2020, she created The Life and the Death of the Little Mermaid and IMIEAMORI, two pictorial works measuring 1.5m by 10m in length, made entirely on paper, without preliminary sketches, using Indian ink and watercolor.

Her works were exhibited from March 16, 2022, to May 16, 2022, at La Folie Barbizon, under the watchful eye of Lionel Bensemoun: "Kajika embraces human nature by revealing her own intimacy, her characters oscillate between violent and gentle emotions. They cry, vomit, kill; they laugh, embrace, love, and bloom, in a constant search for harmony within chaos, for her it is within the greatest dramas that the human being can find the opportunity to let the sweetest flowers of eternity blossom in themselves."`,
            exhibitions: [
              {
                year: "2024",
                title: "Claim for Peace",
                organized_by: "Tresor Foundation",
                city: "Berlin",
                venue: "",
                dates: "Dec 2024",
                type: "SOLO",
              },
              {
                year: "2022",
                title: "Atomic Bombs",
                organized_by: "",
                city: "",
                venue: "La Folie Barbizon",
                dates: "April 2022",
                type: "SOLO",
              },
            ],
            awards: [
              {
                title: "Best Film Award",
                event: "Tbilisi International Animation Festival, Georgia",
                year: 2021,
              },
              {
                title: "Special Mention",
                event: "Future Film Festival, Italy",
                year: 2021,
              },
              {
                title: "Special Mention",
                event: "Anima - Best of Shorts, Belgium",
                year: 2022,
              },
            ],
          },
          frescoes_page: {
            title: "KAJIKA - Frescoes",
            left_image_alt: "The life and death of the little mermaid",
            right_image_alt: "Imieiamori",
            left_title: "The life and death of the little mermaid",
            right_title: "Imieiamori",
            left_subtitle: "1.5x10m, ink on paper",
            right_subtitle: "1.5x10m, ink and watercolor on paper"
          },
          flowers_page: {
            title: "Flowers of Joy",
            description_1: "The Flowers of Joy were born out of the love I had for someone. With this person, I had a sense of happiness and the passage of time was different. This reality never left me, as if it were an integral part of something bigger and everything else had become irrelevant.",
            description_2: "Then I set out to find the perfect line, one that moves without hesitating, like the backhand of a well-trained tennis player or the steps of a ballerina. A form devoid of intrinsic meaning, a line that can stand on its own. After intense work on form and line, the Blue Flowers were born.",
            description_3: "The Blue Flowers of Joy have 5 branches and 5 petals, a star-shaped heart in the center and 5 little points of joy.",
            description_4: "Paintings of Flowers of Joy are simply objects that are nothing more than what they are: a square of paper that I cut myself and where I apply handmade paint. The colors are created with pigments and binders that I found and tested for their unique qualities.",
            description_5: "The colors are organized and adjusted in a form: the Flowers of Joy. That form is the result of more than ten thousand hours of work and research to find the perfect line and form. All colors are applied with no thought or hesitation: the painting is already done as I cut the piece of paper.",
            description_6: "There are no ideas, no symbols behind it, it's a pure act of causality and consequences that begins with the energy of absolute love, joy, and resilience. So I invite everyone to see it as it is: a simple piece of paper with colors on it."
          },
          meta: {
            description: "Kajika Aki is an artist, painter, illustrator, author and animator who confidently depicts her reality through her art. Her work boldly navigates between violence and gentleness, realism and abstraction. Her art is a testament to the resilience of the human spirit, where pain and hardship give birth to precision and truth."
          }
        }
      }
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
