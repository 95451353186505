import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import menuIcon from '../img/flowers.svg';
import { useTranslation } from 'react-i18next';

const Frescoes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadImages = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(loadImages);
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        <Box
          component="img"
          src={menuIcon}
          alt="Loading"
          sx={{
            width: '45px',
            height: '45px',
            animation: 'spin 6s linear infinite',
          }}
        />
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Helmet>
        <title>KAJIKA AKI - Frescoes</title>
      </Helmet>

      {/* Afficher le header après le chargement */}
      <Header />

      {/* Conteneur scindé en deux pour les fresques */}
      <Box 
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          height: '100vh',
          width: '100vw',
        }}
      >
        {/* Section gauche - The life and death of the little mermaid */}
        <Box
          onClick={() => {
            navigate('/littlemermaid');
          }}
          sx={{
            width: { xs: '100%', md: '50%' },
            height: { xs: '50%', md: '100%' },
            backgroundColor: '#f5f5f5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <img
            src="/assets/frescoes/bw-thumbnail.jpg"
            alt={t('frescoes_page.left_image_alt')}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '5%',
              right: '5%',
              color: '#000',
              backgroundColor: '#FFF',
              padding: '3% 5%',
              borderRadius: 1,
              width: '80%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography mb={0.5} variant="h5">
              {t('frescoes_page.left_title')}
            </Typography>
            <Typography variant="subtitle1">
              {t('frescoes_page.left_subtitle')}
            </Typography>
          </Box>
        </Box>

        {/* Section droite - Imieiamori */}
        <Box
          onClick={() => {
            navigate('/imieiamori');
          }}
          sx={{
            width: { xs: '100%', md: '50%' },
            height: { xs: '50%', md: '100%' },
            backgroundColor: '#f5f5f5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <img
            src="/assets/frescoes/color-thumbnail.jpg"
            alt={t('frescoes_page.right_image_alt')}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '5%',
              right: '5%',
              color: '#000',
              backgroundColor: '#FFF',
              padding: '3% 5%',
              borderRadius: 1,
              width: '80%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography mb={0.5} variant="h5">
              {t('frescoes_page.right_title')}
            </Typography>
            <Typography variant="subtitle1">
              {t('frescoes_page.right_subtitle')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
};

export default Frescoes;
