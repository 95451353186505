import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Flowers = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Helmet>
        <title>KAJIKA AKI - Flowers of Joy</title>
      </Helmet>
      <Header />
      <Container
        maxWidth="md"
        sx={{
          width: {
            xs: '100%', // largeur à 100% sur mobile
            md: '50%'   // largeur à 50% à partir de la taille 'md' (medium)
          },
          mt: 12,
          mb: 12
        }}
      >
        <Typography textAlign="center" variant="h3" sx={{ mb: 5 }} gutterBottom>
          {t('flowers_page.title')}
        </Typography>
        <Typography sx={{ lineHeight: '1.8em', textAlign: 'center', mb: 3 }} variant="body1" paragraph>
          {t('flowers_page.description_1')}
        </Typography>
        <Typography sx={{ lineHeight: '1.8em', textAlign: 'center', mb: 3 }} variant="body1" paragraph>
          {t('flowers_page.description_2')}
        </Typography>
        <Typography sx={{ lineHeight: '1.8em', textAlign: 'center', mb: 3 }} variant="body1" paragraph>
          {t('flowers_page.description_3')}
        </Typography>
        <Box component="img" src="/assets/images/flowers.png" alt={t('flowers_page.title')} sx={{ width: '100%', height: 'auto', mt: 4, mb: 4 }} />
        <Typography sx={{ lineHeight: '1.8em', textAlign: 'center', mb: 3 }} variant="body1" paragraph>
          {t('flowers_page.description_4')}
        </Typography>
        <Typography sx={{ lineHeight: '1.8em', textAlign: 'center', mb: 3 }} variant="body1" paragraph>
          {t('flowers_page.description_5')}
        </Typography>
        <Typography sx={{ lineHeight: '1.8em', textAlign: 'center', mb: 3 }} variant="body1" paragraph>
          {t('flowers_page.description_6')}
        </Typography>
      </Container>
    </Box>
  );
};

export default Flowers;
